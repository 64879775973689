import React from 'react'
import PropTypes from 'prop-types'

const Heading = ({ className = '', children }) => {
  return (
    <h1
      className={`text-3xl font-medium text-brand-blue uppercase ${className}`}
    >
      {children}
    </h1>
  )
}

Heading.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Heading
