import React from 'react'
import PropTypes from 'prop-types'

const Container = ({ branded = false, children }) => {
  return branded ? (
    <div className="bg-brand-gray text-white my-6 sm:my-12">
      <div className="container py-8">{children}</div>
    </div>
  ) : (
    <div className="container my-6 sm:my-12">{children}</div>
  )
}

Container.propTypes = {
  branded: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default Container
