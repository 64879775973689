import React from 'react'
import { graphql } from 'gatsby'
import axios from 'axios'
import Img from 'gatsby-image'

import SectionContainer from '../components/section-container'
import Heading from '../components/heading'
import Layout from '../components/layout'
import SEO from '../components/seo'

class ContactPage extends React.Component {
  state = {
    submitting: false,
    error: null,
    showSuccessMessage: false,
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    this.setState({ submitting: true, error: null, showSuccessMessage: false })
    axios({
      method: 'post',
      url: this.props.data.site.siteMetadata.contactFormEndpoint,
      data: new FormData(form),
    })
      .then(() => {
        this.setState({ submitting: false, showSuccessMessage: true })
        form.reset()
      })
      .catch((r) => {
        this.setState({ submitting: false, error: r.response.data.error })
      })
  }

  render() {
    return (
      <Layout>
        <SEO title="Kontakti" />
        <SectionContainer branded>
          Konsultācijas par darba tiesisko attiecību un darba aizsardzības
          normatīvo aktu piemērošanu. Semināri un apmācības. Tiešsaistes
          konsultācijas fiziskām un juridiskām personām, izmantojot Zoom vai
          Skype platformas.
        </SectionContainer>
        <SectionContainer>
          <Heading>Sazinies</Heading>
        </SectionContainer>
        <SectionContainer>
          {this.state.showSuccessMessage ? (
            <div className="bg-green-700 px-4 py-2 rounded text-white text-xl">
              Paldies! Jūsu ziņa ir nosūtīta.
            </div>
          ) : (
            <form
              method="post"
              action={this.props.data.site.siteMetadata.contactFormEndpoint}
              onSubmit={this.handleSubmit}
            >
              {this.state.error && (
                <div className="mb-4 bg-red-700 px-4 py-2 rounded text-white text-xl">
                  {this.state.error}
                </div>
              )}
              <div className="mb-4">
                <label htmlFor="name" className="block uppercase text-sm">
                  Vārds
                  <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  autoComplete="name"
                  className={`mt-1 border py-2 px-3 outline-none font-light focus:border-brand-brown block w-full rounded-md ${
                    this.state.submitting ? 'opacity-75' : ''
                  }`}
                  required
                  disabled={this.state.submitting}
                />
              </div>

              <div className="mb-4">
                <label htmlFor="email" className="block uppercase text-sm">
                  E-pasts
                  <span className="text-red-600">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  autoComplete="email"
                  className={`mt-1 border py-2 px-3 outline-none font-light focus:border-brand-brown block w-full rounded-md ${
                    this.state.submitting ? 'opacity-75' : ''
                  }`}
                  required
                  disabled={this.state.submitting}
                />
              </div>

              <div className="mb-4">
                <label htmlFor="enquiry" className=" uppercase text-sm">
                  Jautājums
                  <span className="text-red-600">*</span>
                </label>
                <textarea
                  id="enquiry"
                  name="enquiry"
                  rows="5"
                  className={`mt-1 border py-2 px-3 outline-none font-light focus:border-brand-brown block w-full rounded-md ${
                    this.state.submitting ? 'opacity-75' : ''
                  }`}
                  required
                  disabled={this.state.submitting}
                ></textarea>
              </div>

              <div className="text-right">
                <button
                  type="submit"
                  className="relative py-2 px-4 border border-brand-gray text-brand-gray font-bold rounded-none hover:bg-brand-gray hover:text-white"
                  disabled={this.state.submitting}
                >
                  Nosūtīt &gt;&gt;
                  {this.state.submitting && (
                    <span className="absolute bg-white hover:bg-brand-gray flex inset-0 items-center justify-center">
                      <svg
                        className="animate-spin h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    </span>
                  )}
                </button>
              </div>
            </form>
          )}
        </SectionContainer>
        <SectionContainer>
          <iframe
            title="Karte"
            className="border border-brand-gray h-64 w-full"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            src="https://www.openstreetmap.org/export/embed.html?bbox=21.021019220352176%2C56.50780877199742%2C21.03893637657166%2C56.51303022464262&amp;layer=mapnik&amp;marker=56.510419588219825%2C21.029977798461914"
          ></iframe>
        </SectionContainer>
        <SectionContainer>
          <div className="flex flex-wrap items-center -mx-2">
            <div className="w-full sm:w-1/2 px-2 mb-4 sm:mb-0">
              <h1 className="text-2xl font-bold text-brand-gray">SIA DTE</h1>
              <ul>
                <li>Vecās Ostmalas Biznesa parks</li>
                <li>Vecā Ostmala 10</li>
                <li>Tālrunis: {this.props.data.site.siteMetadata.tel}</li>
                <li>
                  e-pasts:{' '}
                  <a
                    href={`mailto:${this.props.data.site.siteMetadata.email}`}
                    className="text-brand-blue underline"
                  >
                    {this.props.data.site.siteMetadata.email}
                  </a>
                </li>
              </ul>
            </div>
            <div className="w-full sm:w-1/2 px-2">
              <Img fluid={this.props.data.file.childImageSharp.fluid} />
            </div>
          </div>
        </SectionContainer>
      </Layout>
    )
  }
}

export default ContactPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        tel
        email
        contactFormEndpoint
      }
    }
    file(relativePath: { eq: "dte_sign.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, base64Width: 100, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
